import { type SVGProps } from 'react';

export function UndoIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3 h-3 fill-current'}
      fill='none'
      viewBox='0 0 18 20'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M5.343 1.114a1 1 0 011.4-.2L10.4 3.657 7.657 7.314a1 1 0 01-1.6-1.2l.594-.792A6.145 6.145 0 009 17.142a6.143 6.143 0 004.3-10.529 1 1 0 011.4-1.428 8.143 8.143 0 11-8.185-1.942l-.972-.729a1 1 0 01-.2-1.4z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export function UndoIcon2(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3 h-3 fill-current'}
      fill='none'
      viewBox='0 0 34 34'
    >
      <path
        fillRule='evenodd'
        d='M11.474 5.934c.747.674.806 1.826.132 2.572l-2.65 2.936H23.07c4.53 0 7.893 4.007 7.893 8.547s-3.363 8.547-7.893 8.547H17a1.821 1.821 0 1 1 0-3.643h6.071c2.177 0 4.25-2.016 4.25-4.904s-2.073-4.904-4.25-4.904H8.955l2.65 2.936a1.821 1.821 0 0 1-2.703 2.441l-5.397-5.978a1.82 1.82 0 0 1 0-2.44l5.397-5.979a1.82 1.82 0 0 1 2.572-.131'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
