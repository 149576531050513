import cloneDeep from 'lodash/cloneDeep';
import { useEffect } from 'react';

import { type DtoTTSRenderRequest } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { getLogger } from '../../logger/logger';
import { type EmitterOptions } from '../../utils/emitter';
import { MediaUtils } from '../../utils/media';
import { useMyI18nSettings } from '../Settings/useMyI18nSettings';
import { type TrackId, TrackInitConfigBuilder } from '../VideoMixer';
import {
  createTrackEndAwaiter,
  createTrackRemoveAwaiter,
  createTrackStartAwaiter,
} from '../VideoMixer/createTrackAwaiters';
import {
  RenderedDtoTTSRenderRequest,
  TranslatedDtoTTSRenderRequest,
} from './LocalizedVoiceOversRequestors';
import { type ISubtitlesManager } from './LocalSubtitlesManager';
import { makeMediaElementTracker } from './makeLocalAudioOnlyVideoMixer';
import { extractVariables, type TemplateRenderer } from './VariableRegistry';
import { type VoiceOverRegistryPlan } from './VoiceOverRegistryPlan';

const globalLVOLocalCtrl: { current: LVOLocalCtrl | null } = {
  current: null,
};

export function getGlobalLVOLocalCtrl() {
  return globalLVOLocalCtrl.current;
}

export function setGlobalLVOLocalCtrl(ctrl: LVOLocalCtrl | null) {
  globalLVOLocalCtrl.current = ctrl;
}

export function InitGlobalLVOLocalCtrl(props: { subman: ISubtitlesManager }) {
  const { i18nSettings } = useMyI18nSettings();
  const myLocale = i18nSettings?.value?.voiceOverLocale ?? 'en-US';

  // Create a new local ctrl if needed, based on your currently selected locale.
  useEffect(() => {
    if (globalLVOLocalCtrl.current) {
      globalLVOLocalCtrl.current.destroy();
      globalLVOLocalCtrl.current = null;
    }

    if (myLocale) {
      globalLVOLocalCtrl.current = new LVOLocalCtrl(
        'not-a-venue', // TODO(falcon): revisit
        myLocale,
        props.subman,
        // We are using 'volume' impl (ignored on ios) because VOs do not need
        // volume control.
        makeMediaElementTracker('volume')
      );
    }
  }, [myLocale, props.subman]);

  useEffect(() => {
    return () => {
      globalLVOLocalCtrl.current?.destroy();
      globalLVOLocalCtrl.current = null;
    };
  }, []);
  return null;
}

/** groups a local videomixer + subtitlesmanager + locale. */
export class LVOLocalCtrl {
  readonly vm = this.localVM[0];
  private log = getLogger().scoped('localized-voiceovers-local-ctrl');

  constructor(
    public readonly venueId: string,
    public readonly locale: string,
    public readonly subs: ISubtitlesManager,
    // We are using 'volume' impl (ignored on ios) because VOs do not need
    // volume control.
    public readonly localVM = makeMediaElementTracker('volume')
  ) {
    this.resume();
  }

  async pause() {
    this.log.info('pausing');
    this.vm.pause();
  }

  async resume() {
    this.log.info('playing');
    this.vm.play();
  }

  async reset() {
    this.log.info('resetting');
    this.vm.removeAllTracks();
  }

  destroy() {
    this.log.info('destroying');
    this.vm.destroy();
  }
}

export async function lvoLocalCacheWarm(entry: Nullable<DtoTTSRenderRequest>) {
  if (!entry) return;
  const ctrl = getGlobalLVOLocalCtrl();
  if (!ctrl?.locale) return;

  await lvoCacheWarmForLocales(entry, [ctrl.locale]);
}

async function lvoCacheWarmForLocales(
  entry: Nullable<DtoTTSRenderRequest>,
  locales: string[]
) {
  if (!entry) return;

  const actions = [];

  for (const locale of locales) {
    const action = (async () => {
      const treq = await TranslatedDtoTTSRenderRequest.From(locale, entry);
      await RenderedDtoTTSRenderRequest.Bytes(treq, true);
    })();

    actions.push(action);
  }

  await Promise.all(actions);
}

// While we don't really need "plans" anymore, it's a lot of work to remove them
// all from the playback generation (and elsewhere).
export async function lvoTTSRequestFromPlan(
  plan: VoiceOverRegistryPlan,
  vr: TemplateRenderer
) {
  // We're going to sort, so make a copy.
  const ttsEntries = plan.entries.slice(0);
  // Backwards compat: Sort by number of %variable% in the script so we can pick
  // the most complex from the plan.
  ttsEntries.sort((a, b) => {
    const aVars = extractVariables(a.script).length ?? 0;
    const bVars = extractVariables(b.script).length ?? 0;
    return aVars - bVars;
  });

  const entry = ttsEntries.at(-1);
  if (!entry) return;
  return lvoResolveTTSRequest(entry, vr);
}

/**
 * Call `render` on a given TemplateRenderer using the request's script. Returns
 * null if the script had variables not resolvable by the TemplateRenderer.
 */
export async function lvoResolveTTSRequest(
  req: Nullable<DtoTTSRenderRequest>,
  vr: TemplateRenderer
) {
  if (!req || !req.script) return null;

  const cloned = cloneDeep(req);
  const resolved = await vr.render(cloned.script);

  // If the script could not be resolved, do not try to render it because it
  // will be obviously broken and it's just a waste to call tts on it.
  if (!resolved.resolved) return null;

  cloned.script = resolved.script;

  return cloned;
}

type LVOPlayInfo = {
  locale: string;
  media: Media;
  approximateDurationMs: number;
  trackEnded: Promise<void>;
  trackStarted: Promise<void>;
  trackRemoved: Promise<void>;
};

/**
 * Play a voice over locally, taking into account the current user's current
 * locale. See @see LVOBroadcastPlayer for the opposite version.
 */
export class LVOLocalPlayer {
  private trackId: Nullable<TrackId> = null;

  constructor(
    private req: Nullable<
      DtoTTSRenderRequest & {
        renderedMedia?: Nullable<Media>;
      }
    >,
    private getLVOCtrl = getGlobalLVOLocalCtrl
  ) {}

  onMarkerReached(
    cb: (name: string) => void,
    opts?: EmitterOptions
  ): () => void {
    const ctrl = this.getLVOCtrl();
    if (!ctrl) return () => void 0;
    return ctrl.vm.on('marker-reached', cb, opts);
  }

  async playFromPool(options?: { delayStartMs?: number }) {
    return this.play(options, 'pool-html');
  }

  async play(
    options?: { delayStartMs?: number },
    pool: 'pool-html' | 'pool-web-audio' | 'new' = 'new'
  ) {
    const ctrl = this.getLVOCtrl();
    if (!this.req || !ctrl) return;

    const treq = await TranslatedDtoTTSRenderRequest.From(
      ctrl.locale,
      this.req
    );
    const rreq = await RenderedDtoTTSRenderRequest.From(
      treq,
      false,
      pool,
      this.req.renderedMedia?.url
    );

    const markers = await ParsedMarkers.From(this.req);

    const format = MediaUtils.PickMediaFormat(rreq?.media);
    if (!format) return;

    const start = (ctrl.vm.playheadMs ?? 0) + (options?.delayStartMs ?? 0);

    const cfg = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(start)
      .setDurationMs(format.length)
      .build();

    this.trackId = ctrl.vm.pushTrack(rreq.unplayable.media, cfg);
    const startAwaiter = createTrackStartAwaiter(ctrl.vm, this.trackId);
    const endAwaiter = createTrackEndAwaiter(ctrl.vm, this.trackId);
    const removedAwaiter = createTrackRemoveAwaiter(ctrl.vm, this.trackId);

    for (const mark of markers.markers) {
      // characters are worth the same amount of time, for now
      const ratio = mark.index / markers.withoutMarkers.length;
      ctrl.vm.pushMarker(mark.attrs.name ?? `${this.trackId}-${mark.index}`, {
        timelineTimeStartMs: start + ratio * format.length,
      });
    }

    const ret: LVOPlayInfo = {
      locale: ctrl.locale,
      media: rreq.media,
      approximateDurationMs: format.length,
      trackEnded: endAwaiter,
      trackStarted: startAwaiter,
      trackRemoved: removedAwaiter,
    };

    // Purposefully not awaiting here. Pass in the original, untranslated
    // script. Subtitles always need english.
    ctrl.subs.notify('local', markers.withoutMarkers, ret);

    return ret;
  }

  stop() {
    if (!this.trackId) return;
    const ctrl = this.getLVOCtrl();
    if (!ctrl) return;
    ctrl.vm.removeTrack(this.trackId);
  }
}

class ParsedMarkers {
  static async From(request: DtoTTSRenderRequest) {
    // NOTE(drew): this should eventually accept some sort of
    // RenderedDtoTTSRenderRequest, and then this parsing code can probably be
    // deleted, assuming it eventually gets the ability to receive character
    // timestamps of the actual rendered bytes.
    const chars = request.script.split('');
    const without = [];
    const timings = [];
    let i = 0;
    while (i < chars.length) {
      if (chars[i] === '<' && chars[i + 1].match(/[a-zA-Z/]/)) {
        // Look for <mark
        if (
          chars[i + 1] === 'm' &&
          chars[i + 2] === 'a' &&
          chars[i + 3] === 'r' &&
          chars[i + 4] === 'k'
        ) {
          // Capture tag content, including attributes
          let tagContent = '';
          i += 5; // Move past 'mark'
          // Accumulate all characters until we hit '>'
          while (chars[i] !== '>' && i < chars.length) {
            tagContent += chars[i];
            i++;
          }

          // Parse attributes from the tag content
          const attrs = parseAttributes(tagContent.trim());
          // Store the attributes with timestamp
          timings.push({ index: without.length, attrs });
        }

        // Skip to the end of the tag
        while (chars[i] !== '>' && i < chars.length) i++;
      } else {
        without.push(chars[i]);
      }
      i++;
    }

    return new ParsedMarkers(timings, without.join(''));
  }

  constructor(
    public readonly markers: {
      index: number;
      attrs: Record<string, string>;
    }[],
    public readonly withoutMarkers: string
  ) {}
}

function parseAttributes(tagContent: string) {
  const attributes: Record<string, string> = {};
  // RegExp to match attribute key-value pairs, e.g., name="foobar"
  const attrPattern = /(\w+)\s*=\s*"([^"]*)"/g;
  let match;
  while ((match = attrPattern.exec(tagContent))) {
    const [, key, value] = match;
    attributes[key] = value;
  }
  return attributes;
}
