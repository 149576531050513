import { type Logger } from '@lp-lib/logger-base';

import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function playheadWithinTrackTickHandler(
  prevTime: number,
  _nextTime: number,
  track: IVideoMixerTrack,
  playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter,
  manageDesyncing = true
): void {
  const [startMs, endMs] = track.computeActiveTimelineTimeMs();

  // Is playhead within track?

  if (prevTime >= startMs && prevTime < endMs) {
    // Is track playing and should not be?
    if (track.playing && !playing) {
      log.debug(
        `track ${track.id} needs pause. reason: player is paused and playhead within`,
        {
          playhead: fixed4(prevTime),
          startMs: fixed4(startMs),
          endMs: fixed4(endMs),
        }
      );
      track.pause();
      emitter.emit('track-playstate', track.id, track.playing, prevTime);
    }

    // Should track be playing and is not?
    if (!track.playing && playing) {
      log.debug(
        `track ${track.id} needs play. reason: track is paused and playhead within`,
        {
          playhead: fixed4(prevTime),
          startMs: fixed4(startMs),
          endMs: fixed4(endMs),
        }
      );

      track.play();
      emitter.emit('track-playstate', track.id, track.playing, prevTime);
    }

    if (!manageDesyncing) return;

    // Is track desynced?
    const desynced = track.computeDesync(prevTime);
    if (desynced !== null) {
      const { expectedLocalTimeMs, actualLocalTimeMs } = desynced;

      if (track.isSeekableTo(expectedLocalTimeMs)) {
        track.seekToMs(expectedLocalTimeMs);
        emitter.emit(
          'track-corrected-desync',
          track.id,
          prevTime,
          startMs,
          endMs,
          expectedLocalTimeMs,
          actualLocalTimeMs
        );
        log.debug(`track ${track.id} corrected synchronization`, {
          playhead: fixed4(prevTime),
          startMs: fixed4(startMs),
          endMs: fixed4(endMs),
          expectedLocalTimeMs: fixed4(expectedLocalTimeMs),
          actualLocalTimeMs: fixed4(actualLocalTimeMs),
        });
      } else {
        log.debug(
          `track ${track.id} could not correct synchronization, no matching seekable ranges`,
          {
            playhead: fixed4(prevTime),
            startMs: fixed4(startMs),
            endMs: fixed4(endMs),
            expectedLocalTimeMs: fixed4(expectedLocalTimeMs),
            actualLocalTimeMs: fixed4(actualLocalTimeMs),
            internalVideoDuration: fixed4(track.videoDurationMs),
          }
        );
      }
    }
  }
}
