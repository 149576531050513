import cloneDeep from 'lodash/cloneDeep';

import { type DtoTTSRenderRequest } from '@lp-lib/api-service-client/public';
import { type Media, type MediaData, VolumeLevel } from '@lp-lib/media';

import { DefaultLanguageOption } from '../../i18n/language-options';
import { apiService } from '../../services/api-service';
import { Chan } from '../../utils/Chan';
import { assertDefinedFatal, once } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { UnplayableBytes } from '../../utils/unplayable';

/** Represents a render request that has been transformed into a specific,
 * translated version (e.g. Spanish). */
export class TranslatedDtoTTSRenderRequest {
  static async From(locale: string, request: DtoTTSRenderRequest) {
    if (DefaultLanguageOption.value === locale) {
      const cloned = cloneDeep(request);
      cloned.script = request.script;
      cloned.locale = locale;
      return new TranslatedDtoTTSRenderRequest(locale, cloned);
    }

    const translated = await apiService.translation.translate({
      text: request.script,
      sourceLanguageCode: DefaultLanguageOption.value,
      targetLanguageCode: locale,
    });

    const cloned = cloneDeep(request);
    cloned.script = translated.data.text;
    cloned.locale = locale;

    return new TranslatedDtoTTSRenderRequest(locale, cloned);
  }

  constructor(
    public readonly locale: string,
    public readonly request: DtoTTSRenderRequest
  ) {}
}

/** Represents a render request that has been transformed into something
 * playable via /tts/render */
export class RenderedDtoTTSRenderRequest {
  static concurrencyChannels = {
    cacheWarm: new Chan<true>({ multicast: false }),
    play: new Chan<true>({ multicast: false }),
  };

  static async Bytes(treq: TranslatedDtoTTSRenderRequest, cacheWarm: boolean) {
    const channel = cacheWarm
      ? this.concurrencyChannels.cacheWarm
      : this.concurrencyChannels.play;
    try {
      await channel.take();
      return (await apiService.tts.render(treq.request)).data;
    } finally {
      channel.put(true);
    }
  }

  static async From(
    treq: TranslatedDtoTTSRenderRequest,
    cacheWarm: boolean,
    source: 'pool-html' | 'pool-web-audio' | 'new',
    renderedUrl?: string
  ) {
    const bytes = renderedUrl
      ? await fetch(renderedUrl).then((r) => r.blob())
      : await RenderedDtoTTSRenderRequest.Bytes(treq, cacheWarm);

    const unplayable =
      source === 'pool-html'
        ? UnplayableBytes.FromHTMLAudioPool(bytes)
        : source === 'pool-web-audio'
        ? UnplayableBytes.FromWebAudioPool(bytes)
        : UnplayableBytes.From(bytes);
    unplayable.intoPlayable();
    await once(unplayable.media, 'loadedmetadata');
    const lengthSec = unplayable.media.duration;
    const media = MediaUtils.IntoFakeAudioMediaFromBytes(
      unplayable.media.src,
      bytes,
      lengthSec * 1000
    );
    assertDefinedFatal(media);

    return new RenderedDtoTTSRenderRequest(
      treq.locale,
      treq.request,
      media,
      {
        id: media.id,
        volumeLevel: VolumeLevel.Full,
      },
      unplayable
    );
  }

  constructor(
    public readonly locale: string,
    public readonly request: DtoTTSRenderRequest,
    public readonly media: Media,
    public readonly mediaData: MediaData,
    public readonly unplayable: UnplayableBytes
  ) {}
}

// prime concurrency channels
for (let i = 0; i < 10; i++) {
  RenderedDtoTTSRenderRequest.concurrencyChannels.cacheWarm.put(true);
  RenderedDtoTTSRenderRequest.concurrencyChannels.play.put(true);
}
