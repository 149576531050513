import { proxy } from 'comlink';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { getAudioContext } from '../../services/audio/audio-context';
import { HiddenCanvas } from '../../utils/canvas';
import { Emitter } from '../../utils/emitter';
import { releaseMediaStream } from '../../utils/media';
import { MonotonicallyIncrementingId } from '../../utils/MonotonicallyIncrementingId';
import { createWorkerPoweredLoop } from '../Loop/createWorkerPoweredLoop';
import { createLoop } from '../Loop/loop';
import { type IVideoMixerTrack } from './IVideoMixerTrack';
import { MESNFactory } from './MESNFactory';
import {
  type IEventedVideoMixer,
  type TrackId,
  type TrackInitConfig,
  type VideoMixerEmitter,
  type VideoMixerEvents,
} from './types';
import { fixed4, reportLongDraws, reportTrackDesyncs } from './utils';
import { VideoMixerAudioTrack } from './VideoMixerAudioTrack';
import { VideoMixerCanvasTrack } from './VideoMixerCanvasTrack';
import { VideoMixerImageTrack } from './VideoMixerImageTrack';
import {
  VideoMixerMarker,
  type VideoMixerMarkerConfig,
} from './VideoMixerMarker';
import { audioTickHandler } from './VideoMixerTickHandlers/AudioTickHandler';
import { markerReachedTickHandler } from './VideoMixerTickHandlers/MarkerReachedTickHandler';
import { playheadWithinTrackTickHandler } from './VideoMixerTickHandlers/PlayheadWithinTrackTickHandler';
import { trackStartTickHandler } from './VideoMixerTickHandlers/TrackPlayTickHandler';
import { trackSafeToRemoveTickHandler } from './VideoMixerTickHandlers/TrackSafeToRemoveTickHandler';
import { trackStopTickHandler } from './VideoMixerTickHandlers/TrackStopTickHandler';
import { VideoMixerTrack } from './VideoMixerTrack';

export const log = logger.scoped('video-mixer');

type StatsObserver = {
  beforeDraw: () => void;
  afterDraw: () => void;
  beforeTick: () => void;
  afterTick: () => void;
};

type VideoMixerInitConfig = {
  renderWidth: number;
  renderHeight: number;
  tickFps?: number;
  drawFps?: number;
  autoRemoveTracksAfterSafeMs?: number | typeof Infinity;
  audioRenderingOnly?: boolean;
  stats?: StatsObserver;
  cleanupMESN?: boolean;
};

/**
 * The VideoMixer is effectively a representation of a list of tracks in a
 * digital video or audio editor: each "track" contains a source and metadata
 * about when to play and what effects (and how, as time progresses) to add to a
 * track. The video tracks (and audio) are mixed into a single output stream.
 */
export class VideoMixer implements IEventedVideoMixer {
  protected emitter: VideoMixerEmitter = new Emitter<VideoMixerEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  private trackIdGen = new MonotonicallyIncrementingId<TrackId>('vm-track');

  private tracks: IVideoMixerTrack[] = [];

  private destMediaStream: MediaStream;

  private state: 'playing' | 'paused' = 'paused';
  private playhead = 0;
  private destroyLoop: () => void;

  private autoRemoveTracksAfterSafeMs: number | typeof Infinity;
  private markers: Map<VideoMixerMarker['id'], VideoMixerMarker> = new Map();
  private audioRenderingOnly: boolean;
  private statsObserver: StatsObserver | null;
  private cleanupMESN: boolean;

  constructor(
    config: VideoMixerInitConfig,

    loopUseWorker = getFeatureQueryParam(
      'game-on-demand-video-mixer-loop-use-worker'
    ),
    loopMethod = getFeatureQueryParamArray(
      'game-on-demand-video-mixer-loop-method'
    ),
    createLoopFn = createLoop,

    // injectables for testing

    protected audioCtx = getAudioContext(),
    protected msdn = audioCtx.createMediaStreamDestination(),
    protected gain = audioCtx.createGain(),
    private mesn = new MESNFactory(gain),

    private ocvs = new HiddenCanvas('video-mixer'),
    private cvs = ocvs.cvs,
    private ctx = cvs.getContext('2d', { alpha: false })
  ) {
    this.gain.connect(this.msdn);
    this.ocvs.attach();
    if (this.ctx) {
      this.ctx.imageSmoothingEnabled = false;
    }

    const {
      renderWidth,
      renderHeight,
      tickFps,
      drawFps,
      autoRemoveTracksAfterSafeMs,
      audioRenderingOnly,
      cleanupMESN,
    } = {
      tickFps: 24,
      drawFps: 24,
      autoRemoveTracksAfterSafeMs: 5000,
      ...config,
    };

    this.autoRemoveTracksAfterSafeMs = autoRemoveTracksAfterSafeMs;
    this.setOutputDimensions(renderWidth, renderHeight);
    // According to MDN regarding captureStream(frameRate): "If not set, a new
    // frame will be captured each time the canvas changes". This is what we
    // want. The VideoMixer only renders a new frame when told.
    this.destMediaStream = this.cvs.captureStream();
    this.destMediaStream.addTrack(this.msdn.stream.getAudioTracks()[0]);

    this.audioRenderingOnly = audioRenderingOnly ?? false;

    if (audioRenderingOnly) {
      for (const t of this.destMediaStream.getVideoTracks()) {
        this.destMediaStream.removeTrack(t);
        t.stop();
      }
    }

    this.statsObserver = config.stats ?? null;
    this.cleanupMESN = cleanupMESN ?? true;

    const MS_IN_SEC = 1000;
    const drawTime = MS_IN_SEC / drawFps;
    const updateTime = MS_IN_SEC / tickFps;

    if (loopUseWorker) {
      const loopWorker = createWorkerPoweredLoop();
      loopWorker.startAccumulated(
        loopMethod,
        {
          updateTime,
          drawTime,
          panicAt: Infinity, // never panic
        },
        proxy((dt) => this.tick(dt)),
        proxy((_interp, _dt) => this.draw())
      );
      this.destroyLoop = () => {
        loopWorker.stop();
        loopWorker.terminate();
      };
    } else {
      this.destroyLoop = createLoopFn({
        kind: loopMethod,
        updateTime,
        drawTime,
        panicAt: Infinity, // never panic
        update: (dt) => this.tick(dt),
        draw: (_interp, _dt) => this.draw(),
      });
    }

    if (
      this.autoRemoveTracksAfterSafeMs > 0 &&
      this.autoRemoveTracksAfterSafeMs < Infinity
    ) {
      this.on('track-safe-to-remove', (trackId, playheadMs) => {
        log.info(`auto removing ${trackId} after safeMs`, {
          playheadMs: fixed4(playheadMs),
        });
        this.removeTrack(trackId);
      });
    }

    reportTrackDesyncs(this, () => this.tracks, log);
    reportLongDraws(this, log);

    log.info(
      `created ${renderWidth}x${renderHeight} px, ${drawFps}fps draw, ${tickFps}fps tick`
    );
  }

  setOutputDimensions(renderWidth: number, renderHeight: number): void {
    this.cvs.width = renderWidth;
    this.cvs.height = renderHeight;
  }

  getOutputDimensions(): readonly [number, number] {
    return [this.cvs.width, this.cvs.height] as const;
  }

  getOutputMediaStream(): MediaStream {
    return this.destMediaStream;
  }

  pause(): void {
    if (!this.playing) return;
    this.state = 'paused';
    log.info('pausing', { playhead: this.playhead });
    this.emitter.emit('playstate', this.playing, this.playhead);
  }

  play(): void {
    if (this.playing) return;
    this.state = 'playing';
    log.info('playing', { playhead: this.playhead });
    this.emitter.emit('playstate', this.playing, this.playhead);
  }

  /**
   * Seek the player instantaneously. Tracks will behave as you expect. Be
   * prepared for events such as track-start/track-end to fire again if track
   * boundaries are crossed again! Note: There are no bounds restrictions since
   * the playhead will advance from wherever it is told to start, including a
   * negative value.
   */
  seek(playheadMs: number): void {
    log.info(`seeking to ${fixed4(playheadMs)}`, {
      playhead: fixed4(this.playhead),
      playing: this.playing,
    });
    this.playhead = playheadMs;
    this.emitter.emit('seek', this.playing, this.playhead);
  }

  get playing(): boolean {
    return this.state === 'playing';
  }

  get playheadMs(): number {
    return this.playhead;
  }

  /**
   * Push (add) a video as a new track with the given config for
   * scheduled/synchronized playback. Once given to the VideoMixer, it should be
   * assumed that all playback control and ownership over the video is
   * rescinded.
   *
   * Note: durationMs does not need to be the same as video.duration! A block
   * recording can be longer or shorter than the underlying video...
   */
  pushTrack(
    video:
      | HTMLVideoElement
      | HTMLImageElement
      | HTMLAudioElement
      | HTMLCanvasElement,
    trackDesc: TrackInitConfig
  ): TrackId {
    if (!trackDesc.durationMs)
      throw new Error('durationMs cannot be zero or otherwise ambiguous.');

    let t;

    if (video instanceof HTMLVideoElement) {
      t = new VideoMixerTrack(this.trackIdGen.next(), video, trackDesc);
      this.mesn.wire(video);
    } else if (video instanceof HTMLAudioElement) {
      t = new VideoMixerAudioTrack(this.trackIdGen.next(), video, trackDesc);
      this.mesn.wire(video);
    } else if (video instanceof HTMLCanvasElement) {
      t = new VideoMixerCanvasTrack(this.trackIdGen.next(), video, trackDesc);
    } else {
      t = new VideoMixerImageTrack(this.trackIdGen.next(), video, trackDesc);
    }

    return this.addTrack(t);
  }

  private addTrack(t: IVideoMixerTrack) {
    // Always keep tracks sorted according to readonly zLayer. Higher == drawn
    // later, "back to front".
    this.tracks.push(t);
    this.tracks.sort((a, b) => a.config.zLayer - b.config.zLayer);

    log.info(`pushed ${t.sourceDescription()}`, {
      config: {
        timelineTimeStartMs: fixed4(t.config.timelineTimeStartMs),
        durationMs: fixed4(t.config.durationMs),
        loop: t.config.loop,
      },
      playhead: fixed4(this.playhead),
      playing: this.playing,
    });

    return t.id;
  }

  async removeTrack(trackId: TrackId | null): Promise<void> {
    if (trackId === null) return;
    for (const [idx, t] of this.tracks.entries()) {
      if (t.id === trackId) {
        log.info(`removing ${t.sourceDescription()} at index ${idx}`, {
          playhead: fixed4(this.playhead),
        });
        this.emitter.emit(
          'track-remove',
          t.id,
          this.playhead,
          t.getAudioSource()
        );
        this.tracks.splice(idx, 1);
        const media = await t.destroy();

        if (media && this.cleanupMESN) {
          // There is no pooling, destroy the MESN connection.
          this.mesn.unwire(media);
        } else if (media) {
          // There could be pooling, reset volume for the next use.
          const [, gain] = this.mesn.wire(media);
          gain.gain.cancelScheduledValues(0);
          gain.gain.value = 1;
        } else {
          // Volume control is using HTMLAudioElement.volume. This is already
          // reset in VideoMixerTrack.destroy().
        }

        break;
      }
    }
  }

  async removeAllTracks(): Promise<void> {
    log.info('removing all tracks');

    const tids = this.tracks.map((t) => t.id);
    for (const tid of tids) {
      await this.removeTrack(tid);
    }

    this.tracks.length = 0;
    log.info('removed all tracks');
  }

  patchTrack(
    trackId: TrackId | null,
    patch: Partial<TrackInitConfig>
  ): boolean {
    if (!trackId) return false;
    const track = this.tracks.find((t) => t.id === trackId);
    if (!track) return false;
    return track.acceptPatch(patch);
  }

  /**
   * Returns the start and end timelineTimes the track will be "active", aka
   * monitored for playback syncing, drawn, events scheduled, etc.
   */
  getTrackActiveTimelineTimeMs(
    query: TrackId | null
  ): readonly [number, number] | null {
    if (!query) return null;
    const track = this.tracks.find((t) => t.id === query);
    return track?.computeActiveTimelineTimeMs() ?? null;
  }

  /**
   * Return the track-relative elapsed time from the start of the track to the
   * playhead's current position. 0 can mean the track has not started to play
   * yet, while a value equal to the duration implies the track is finished
   * playing for now.
   */
  getTrackElapsedTimeMs(query: TrackId | null): number | null {
    const desc = this.getTrackActiveTimelineTimeMs(query);
    if (!desc) return null;
    const [startMs, endMs] = desc;
    const elapsed = this.playhead - startMs;
    return Math.min(Math.max(0, elapsed), endMs);
  }

  pushMarker(id: VideoMixerMarker['id'], config: VideoMixerMarkerConfig): void {
    const marker = new VideoMixerMarker(id, config);
    this.markers.set(marker.id, marker);
  }

  removeMarker(id: VideoMixerMarker['id']): void {
    this.markers.delete(id);
  }

  debugMarkers(): VideoMixerMarker[] {
    return Array.from(this.markers)
      .map(([, m]) => m)
      .sort(
        (a, b) => a.config.timelineTimeStartMs - b.config.timelineTimeStartMs
      );
  }

  private tick(elapsed: number) {
    this.statsObserver?.beforeTick();
    const prevTime = this.playhead;
    const nextTime = this.playhead + elapsed;

    // Ensure stable iteration outside of possible mutations
    const markersToProcess = Array.from(this.markers);
    for (const [, marker] of markersToProcess) {
      markerReachedTickHandler(
        prevTime,
        nextTime,
        marker,
        this.playing,
        log,
        this.emitter
      );
    }

    // Ensure we have stable iteration between event emissions, which could
    // mutate the list of tracks.
    const tracksToProcess = this.tracks.slice();

    for (const track of tracksToProcess) {
      audioTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter,
        this.audioCtx,
        this.mesn
      );

      trackStartTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      playheadWithinTrackTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      trackStopTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      trackSafeToRemoveTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter,
        this.autoRemoveTracksAfterSafeMs
      );
    }

    if (this.playing) {
      this.playhead = nextTime;
    }
    this.statsObserver?.afterTick();
  }

  private draw() {
    if (this.audioRenderingOnly) return;
    this.statsObserver?.beforeDraw();
    const start = performance.now();
    this.ctx?.clearRect(0, 0, this.cvs.width, this.cvs.height);
    const firstFrames = new Set<TrackId>();

    for (const t of this.tracks) {
      const [activeStart, activeEnd] = t.computeActiveTimelineTimeMs();
      if (
        this.playhead >= activeStart &&
        this.playhead < activeEnd &&
        this.ctx
      ) {
        const renderCount = t.draw(this.playhead, this.ctx);
        if (renderCount === 1) {
          // This is the first time the track has rendered.

          // TODO(drew): this will be inaccurate if the player is seeked _back_
          // to the first frame, since nothing resets the renderCount of the
          // track. It's basically the only stateful property of the track.
          firstFrames.add(t.id);
        }
      }
    }

    const end = performance.now();
    const delta = end - start;
    const limitMs = 16;
    if (delta > limitMs)
      this.emitter.emit('long-draw', this.playhead, limitMs, delta);

    // Emit all at once to avoid iteration garbage and unnecessary processing.
    if (firstFrames.size > 0) {
      this.emitter.emit(
        'track-first-frame-render',
        firstFrames,
        this.playheadMs
      );
    }
    this.statsObserver?.afterDraw();
  }

  async destroy(): Promise<void> {
    log.info('destroying');
    this.emitter.emit('before-destroy');
    this.destroyLoop();
    this.gain.disconnect();
    this.msdn.disconnect();
    releaseMediaStream(this.destMediaStream);
    releaseMediaStream(this.msdn.stream);
    this.ocvs.detach();
    await this.removeAllTracks();
    this.markers.clear();
    this.emitter.clear();
    log.info('destroyed');
  }
}
