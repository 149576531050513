import { getAudioContext } from '../../services/audio/audio-context';
import { HTMLAudioPool, WebAudioPool } from '../../utils/unplayable';
import { VideoMixer } from '../VideoMixer';
import { MediaElementTracker } from '../VideoMixer/MediaElementTracker';

export function makeMediaElementTracker(volumeControl: 'web-audio' | 'volume') {
  const vm = new MediaElementTracker(
    {
      // We are always pooling, so never cleanup
      cleanupMESN: false,
    },
    false,
    'timer-accumulated'
  );

  vm.on('track-remove', (_trackId, _playheadMs, element) => {
    if (!element) return;
    WebAudioPool.GetOptionalInstance()?.release(element);
    HTMLAudioPool.GetOptionalInstance()?.release(element);
  });

  vm.initializeVolumeChangeSupport(
    getAudioContext().destination,
    getAudioContext(),
    volumeControl
  );

  return [vm] as const;
}

export function makeAudioOnlyVideoMixer(pool: 'pool' | 'no-pool') {
  const vm = new VideoMixer(
    {
      renderHeight: 100,
      renderWidth: 100,
      audioRenderingOnly: true,
      cleanupMESN: pool === 'pool' ? false : true,
    },
    false,
    'timer-accumulated'
  );

  vm.on('track-remove', (_trackId, _playheadMs, element) => {
    if (!element) return;
    WebAudioPool.GetOptionalInstance()?.release(element);
    HTMLAudioPool.GetOptionalInstance()?.release(element);
  });

  return vm;
}
